import { FC } from "react";


export const FilterTextRow: FC<{
  changeHandler: (e: any) => void;
  value: string;
  showBool?: boolean;
  labelCol?: string;
  inputCol?: string;
  placeholder?: string;
}> = ({
  value,
  showBool = true,
  changeHandler,
  labelCol = "",
  inputCol = "",
  placeholder = ""
}) => {

    return (
      <div className="row form-group mt-3">
        <div className={"text-end my-auto " + labelCol}>
          {showBool ? (
            <label htmlFor="teacherFilter" className="col-form-label">
              Filter:
            </label>
          ) : (
            <div></div>
          )}
        </div>
        <div className={"my-auto " + inputCol}>
          <input
            type="text"
            className="form-control"
            value={value}
            placeholder={placeholder}
            onChange={(e) => changeHandler(e.target.value)}
          />
        </div>
      </div>
    )
  }