import { FC } from "react";
import { useModal, CustomModal, ModalButton } from "../../components/ui/CustomModal";

import { Spinner } from "../../components/ui/Spinner";
import { useRemoveAllCoursesMutation } from "../../hooks/configHooks";

export const ConfigDeleteAll: FC<{
}> = () => {
  const modalControl = useModal(`DeleteAllButton`, "sm");
  const removeAllMutation = useRemoveAllCoursesMutation();

  const removeAllHandler = () => {
    removeAllMutation.mutate();
    modalControl.hide();
  };

  const ModalButton: ModalButton = ({ showModal }) => (
    <div className="text-center">
      {removeAllMutation.isLoading ? (
        <Spinner className="text-danger" />
      ) : (
        <button className="btn btn-danger mb-3" onClick={showModal}>
          Remove All
        </button>
      )}
    </div>
  );

  return (
    <CustomModal controls={modalControl} ModalButton={ModalButton}>
      <div className="modal-body text-center">
        <div className="bi bi-pause-circle text-danger fs-1"></div>
        <div className="fw-bold">Are you sure?</div>
        <div className="fw-light my-3 text-break">
          Do you want to delete all courses? This action cannot be undone.
        </div>
        <div className="row">
          <div className="col">
            <button
              className="btn btn-secondary"
              onClick={() => modalControl.hide()}
            >
              Cancel
            </button>
          </div>
          <div className="col">
            <button className="btn btn-danger" onClick={removeAllHandler}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};
