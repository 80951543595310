import { FC, FormEvent } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useBlacklistRecordingMutation } from "../../hooks/courseHooks";
import { Recording } from "../../models/Recording";


export const RecordingCard: FC<{
  courseId: string;
  recording: Recording;
  canBlacklist?: boolean;
}> = ({ courseId, recording, canBlacklist = false }) => {
  const navigate = useNavigate();
  const blacklistMutation = useBlacklistRecordingMutation(courseId)

  const PlayRecording = (e: FormEvent) => {
    e.preventDefault()
    navigate({
      pathname: `/recording/${recording.id}`
    })
  }

  const blacklistHandler = () => {
    blacklistMutation.mutate(recording.ivcPath)
  }

  const copyHandler = () => {
    navigator.clipboard.writeText(recording.url).then(() => toast.success("Copied link to clipboard!"))
  }

  if (!canBlacklist) return (
    <div className="card-group col-lg-3 col-md-6 mb-3">
      <div className="card">
        <img className="card-img-top img-responsive"
          src="/thumbnail.jpg"
          alt="thumbnail"
          onClick={(e) => PlayRecording(e)}
        />
        <div className="card-body text-center py-2">
          <div className="row">
            <div className="text-center col my-auto"
              onClick={(e) => PlayRecording(e)}>
              {new Date(recording.sTime).toDateString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="card-group col-lg-3 col-md-6 mb-3">
      <div className="card">
        <img className="card-img-top img-responsive"
          src="/thumbnail.jpg"
          alt="thumbnail"
          onClick={(e) => PlayRecording(e)}
        />
        <div className="card-body text-center py-2">
          <div className="row">
            <div className="text-end col my-auto"
              onClick={(e) => PlayRecording(e)}>
              {new Date(recording.sTime).toDateString()}
            </div>
            <div className="col-2">
              <button className="btn btn-dark px-1 py-0"
                onClick={blacklistHandler}>
                <i className="bi bi-x-square"></i>
              </button>
            </div>
            <div className="col-2">
              <button className="btn btn-bold px-1 py-0" onClick={copyHandler}>
                <i className="bi bi-link-45deg"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}