import { FC } from "react";
import { useWhitelistRecordingMutation } from "../../hooks/courseHooks";
import { Recording } from "../../models/Recording";


export const BlacklistRecordingCard: FC<{
  courseId: string;
  recording: Recording;
}> = ({ courseId, recording }) => {
  const whitelistMutation = useWhitelistRecordingMutation(courseId)

  const clickHandler = () => {
    whitelistMutation.mutate(recording.ivcPath);
  }

  return (
    <div className="card-group col-lg-3 col-md-6 mb-3">
      <div className="card">
        <img className="card-img-top img-responsive"
          style={{ filter: "grayscale(100%)"}}
          src="/thumbnail.jpg"
          alt="thumbnail"
        />
        <div className="card-body text-center py-2">
          <div className="row">
            <div className="text-center col ms-5 my-auto">
              {new Date(recording.sTime).toDateString()}
            </div>
            <div className="col-2">
              <button className="btn btn-bold px-1 py-0" onClick={clickHandler}>
                <i className="bi bi-plus-square"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}