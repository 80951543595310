import axios, { AxiosRequestHeaders } from "axios";
import toast from "react-hot-toast";
import { Course } from "../models/Course";
import { Recording } from "../models/Recording";
import { snakeToCamel } from "./apiMapper";

export const courseService = {
  async getStudentCourses(authHeader: AxiosRequestHeaders): Promise<Course[]> {
    const url = `/api/course/courses`;
    const options = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getCourse(
    courseId: string,
    authHeader: AxiosRequestHeaders
  ): Promise<Course> {
    const url = `/api/course/${courseId}`;
    const options = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getWhitelistedRecordings(
    courseId: string,
    authHeader: AxiosRequestHeaders
  ): Promise<Recording[]> {
    const url = `/api/course/${courseId}/recordings/whitelist`;
    const options = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getBlacklistedRecordings(
    courseId: string,
    authHeader: AxiosRequestHeaders
  ): Promise<Recording[]> {
    const url = `/api/course/${courseId}/recordings/blacklist`;
    const options = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async blacklistCourse(course_id: string, authHeader: AxiosRequestHeaders) {
    const url = `/api/course/blacklist/${course_id}`;
    const options = { headers: authHeader };
    const response = await axios.post(url, options);
    toast.success("Blacklisted Course!", {
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
    return snakeToCamel(response.data);
  },
  async whitelistCourse(course_id: string, authHeader: AxiosRequestHeaders) {
    const url = `/api/course/whitelist/${course_id}`;
    const options = { headers: authHeader };
    const response = await axios.post(url, options);
    toast.success("Whitelisted Course!");
    return snakeToCamel(response.data);
  },
  async blacklistRecording(
    recordingIvcPath: string,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/recording/blacklist`;
    const options = { headers: authHeader };
    const body = { recording_ivc_path: recordingIvcPath };
    const response = await axios.post(url, body, options);
    return snakeToCamel(response.data);
  },
  async whitelistRecording(
    recordingIvcPath: string,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/recording/whitelist`;
    const options = { headers: authHeader };
    const body = { recording_ivc_path: recordingIvcPath };
    const response = await axios.post(url, body, options);
    return snakeToCamel(response.data);
  },
};
