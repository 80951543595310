import { FormEvent, useRef } from "react";
import toast from "react-hot-toast";
import {
  usePostNewCourseBatchToConfigMutation,
} from "../../hooks/configHooks";

export const BatchUpload = () => {
  const selectedFile = useRef<HTMLInputElement>(null);
  const fileReader = new FileReader();
  const newCoursesMutation = usePostNewCourseBatchToConfigMutation();

  const handleFileRead = () => {
    const content = fileReader.result;
    if (content != null){
      newCoursesMutation.mutate(content.toString().trim());
    }
  };

  const batchUploadHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedFile.current?.files) {
      fileReader.onloadend = handleFileRead;
      if (selectedFile.current?.files[0].type == "text/csv") {
        fileReader.readAsText(selectedFile.current?.files[0]);
      } else {
        toast.error("Invalid file type, Please upload a .CSV");
      }
    }
  };

  return (
    <div className="container">
      <h2>No Courses On File</h2>
      <form onSubmit={batchUploadHandler} className="border px-4">
        <div>
          <label className="my-3 form-label">Upload a Course List (.CSV)</label>
          <input
            type="file"
            accept=".csv"
            className="form-control"
            ref={selectedFile}
          />
        </div>
        <button className="btn btn-success my-3">Batch Upload</button>
      </form>
    </div>
  );
};
