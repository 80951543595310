import { useMutation, useQuery } from "react-query";
import { configService } from "../services/configService";
import { getQueryClient } from "../services/queryClient";
import { useAccessTokenHeader } from "./userHooks";

const configCoursesKey = "configCoursesKey";
const queryClient = getQueryClient();

export const usePostNewCourseToConfigMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      newCourseId,
      newCourseName,
    }: {
      newCourseId: string;
      newCourseName: string;
    }) =>
      await configService.postNewCourseToConfig(
        newCourseId,
        newCourseName,
        authHeader
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([configCoursesKey]);
      },
    }
  );
};

export const usePostNewCourseBatchToConfigMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async (file: string) =>
      await configService.postNewCourseBatchToConfig(
        file,
        authHeader
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([configCoursesKey]);
      },
    }
  );
};

export const useRemoveCourseMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async (course_id: string) =>
      await configService.postRemoveCourse(course_id, authHeader),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([configCoursesKey]);
      },
    }
  );
};

export const useRemoveAllCoursesMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async () => await configService.postRemoveAllCourses(authHeader),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([configCoursesKey]);
      },
    }
  );
};

export const useConfigCoursesQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery([configCoursesKey], async () => {
    return configService.getConfigCourses(authHeader);
  });
};
