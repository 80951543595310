import { FormEvent, useState } from "react"
import { ConfigSection } from "./ConfigSection"
import { NewCourseForm } from "./NewCourseForm"

export const Configuration = () => {
    
    return (
        <>
        <div className="text-center container">
            <h1>Configuration</h1>
            <hr/>
        </div>
        <div className="container col-lg-4 col-10">
            <NewCourseForm/>
            <hr/>
            <div className="text-center mt-4">
                <ConfigSection/>
            </div>
        </div>
        </>
    )
}