import axios, { AxiosRequestHeaders } from "axios";
import toast from "react-hot-toast";
import { Person } from "../models/Person";
import { snakeToCamel } from "./apiMapper";

export const teacherService = {
  async getWhitelistedTeachers(
    authHeader: AxiosRequestHeaders
  ): Promise<Person[]> {
    const url = `/api/teacher/whitelisted`;
    const options = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getBlacklistedTeachers(
    authHeader: AxiosRequestHeaders
  ): Promise<Person[]> {
    const url = `/api/teacher/blacklisted`;
    const options = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async getIsTeacherOrAdmin(authHeader: AxiosRequestHeaders): Promise<boolean> {
    const url = "/api/teacher/isTeacherOrAdmin";
    const options = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async blacklistTeacher(teacherId: string, authHeader: AxiosRequestHeaders) {
    const url = `/api/teacher/blacklist/${teacherId}`;
    const options = { headers: authHeader };
    const response = await axios.post(url, options);
    toast.success("Blacklisted Teacher!", {
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    });
    return snakeToCamel(response.data)
  },
  async whitelistTeacher(teacherId: string, authHeader: AxiosRequestHeaders) {
    const url = `/api/teacher/whitelist/${teacherId}`;
    const options = { headers: authHeader };
    const response = await axios.post(url, options);
    toast.success("Whitelisted Teacher!")
    return snakeToCamel(response.data)
  }
};
