import { useQuery } from "react-query";
import { teacherService } from "../services/teacherService";
import { useAccessTokenHeader } from "./userHooks";

const isTeacherKey = "isTeacherKey"


export const useGetIsTeacherOrAdminQuery = () => {
  const authHeader = useAccessTokenHeader()
  return useQuery([isTeacherKey], async () => {
    return teacherService.getIsTeacherOrAdmin(authHeader)
  })
}
