import axios, { AxiosRequestHeaders } from "axios";
import toast from "react-hot-toast";
import { Course } from "../models/Course";
import { snakeToCamel } from "./apiMapper";

export const configService = {
  async postNewCourseToConfig(
    courseId: string,
    courseName: string,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/config/add`;
    const options = { headers: authHeader };
    const body = { course_id: courseId, course_name: courseName };
    const response = await axios.post(url, body, options);
    toast.success(`Added ${courseName} To Config`);
    return snakeToCamel(response.data);
  },
  async postNewCourseBatchToConfig(
    file: string,
    authHeader: AxiosRequestHeaders
  ) {
    const response = await toast.promise(
      (async () => {
        const url = `/api/config/addbatch`;
        const options = { headers: authHeader };
        const body = { file: file };
        const response = await axios.post(url, body, options);
        return response
      })(),
      {
        loading: "Adding Courses...",
        success: "Added Courses",
        error: "Failed to add courses",
      }
    );
    return snakeToCamel(response.data)
  },
  async getConfigCourses(authHeader: AxiosRequestHeaders): Promise<Course[]> {
    const url = `/api/config/courses`;
    const options = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  },
  async postRemoveCourse(courseId: string, authHeader: AxiosRequestHeaders) {
    const url = `/api/config/remove/${courseId}`;
    const options = { headers: authHeader };
    const response = await axios.post(url, options);
    toast.success("Removed Course!");
    return snakeToCamel(response.data);
  },
  async postRemoveAllCourses(authHeader: AxiosRequestHeaders) {
    const url = `/api/config/remove`;
    const options = { headers: authHeader };
    const response = await axios.post(url, options);
    toast.success("Removed All Courses!");
    return snakeToCamel(response.data);
  },
};
