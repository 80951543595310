import { FormEvent, useState } from "react"
import toast from "react-hot-toast";
import { usePostNewCourseToConfigMutation } from "../../hooks/configHooks";

export const NewCourseForm = () => {
  const [newCourseId, setNewCourseId] = useState("")
  const [newCourseName, setNewCourseName] = useState("")

  const newCourseMutation = usePostNewCourseToConfigMutation()

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (newCourseId.length === 6) {
      newCourseMutation.mutateAsync({ newCourseId, newCourseName }).then(() => {
        setNewCourseId("")
        setNewCourseName("")
      })
    }
    else toast.error("Given course ID not a valid course")
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3 row">
          <div className="col-md-3  align-self-center">
            <label className="form-label" htmlFor="course_id">Course ID:</label>
          </div>
          <div className="col">
            <input
              className="form-control col"
              type={"text"} id="course_id"
              placeholder="Enter ID"
              value={newCourseId}
              onChange={e => setNewCourseId(e.target.value)}>
            </input>
          </div>
        </div>
        <div className="form-group mb-3 row">
          <div className="col-md-3 align-self-center">
            <label className="form-label" htmlFor="course_name">Course Name:</label>
          </div>
          <div className="col">
            <input
              className="form-control col"
              type={"text"} id="course_name"
              placeholder="Enter Class Name"
              value={newCourseName}
              onChange={e => setNewCourseName(e.target.value)}>
            </input>
          </div>
        </div>
        <div className="mb-2 row">
          <button type="submit" className="btn btn-bold" disabled={newCourseMutation.isLoading}>Add Course</button>
        </div>
      </form>
    </>
  )
}