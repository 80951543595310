import { useState } from "react";
import { FilterTextRow } from "../../components/forms/FilterTextRow";
import { Spinner } from "../../components/ui/Spinner";
import { useBlacklistCourseMutation, useGetBlacklistedTeachersQuery, useGetWhitelistedTeachersQuery } from "../../hooks/adminHooks";
import { BlacklistedTeacherSection } from "./BlacklistedTeacherSection";
import { TeacherSection } from "./TeacherSection";

export const Admin = () => {
  const [filterText, setFilter] = useState("");
  const whitelistedTeacherQuery = useGetWhitelistedTeachersQuery();
  const blacklistedTeacherQuery = useGetBlacklistedTeachersQuery();
  const filteredTeachers = (whitelistedTeacherQuery.data ?? [])
    .filter(
      t => (t.courses?.filter(c => c.name.toLowerCase().includes(filterText.toLowerCase())).length != 0) ||
        (t.name.toLowerCase().includes(filterText.toLowerCase()))
    )
  const filteredBlacklistTeachers = (blacklistedTeacherQuery.data ?? [])
    .filter(
      t => (t.courses?.filter(c => c.name.toLowerCase().includes(filterText.toLowerCase())).length != 0) ||
        (t.name.toLowerCase().includes(filterText.toLowerCase()))
    )


  if (whitelistedTeacherQuery.isFetching) return <Spinner />;

  if (filteredTeachers.length == 0 && filteredBlacklistTeachers.length == 0) return (
    <div className="text-center container">
      <h1>Admin</h1>
      <hr/>
      <h3>No IVC teachers or courses found</h3>
    </div>
  )

  return (
    <>
      <div className="text-center container">
        <h1>Admin</h1>
        <hr/>
      </div>
      <FilterTextRow
        changeHandler={(e: any) => setFilter(e)}
        value={filterText}
        showBool={false}
        labelCol={"col-lg-5 col-2"}
        inputCol={"col-lg-2 col-8"}
        placeholder={"Filter by professor or course"}
      />
          <div className="row justify-content-center">
            <div className="col-lg-4 col-10">
              {filteredTeachers?.map((t) => (
                <TeacherSection key={t.id} teacher={t} />
              ))}
              <hr />
              {filteredBlacklistTeachers.map((t) => (
                <BlacklistedTeacherSection key={t.id} teacher={t} />
              ))}
            </div>
          </div>
    </>
  );
};
