import { useConfigCoursesQuery, useRemoveAllCoursesMutation, useRemoveCourseMutation } from "../../hooks/configHooks";
import { BatchUpload } from "./BatchUpload";
import { ConfigDeleteAll } from "./ConfigDeleteAll";

export const ConfigSection = () => {
  const configCoursesQuery = useConfigCoursesQuery();
  const removeCourseMutation = useRemoveCourseMutation();

  const removeCourseHandler = (courseId: string) =>{
    removeCourseMutation.mutate(courseId)
  }

  if (!configCoursesQuery.data || configCoursesQuery.data?.length == 0 ) 
    return (
      <BatchUpload/>
    )
    
  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Course ID</th>
            <th>Course Name</th>
          </tr>
        </thead>
        <tbody>
          {configCoursesQuery.data?.map((c) => (
            <tr key={c.id}>
              <td className="align-middle">{c.id}</td>
              <td className="align-middle">{c.name}</td>
              <td>
                <button 
                className="btn btn-danger bg-danger px-1 py-0 text-white"
                onClick={() => removeCourseHandler(c.id)}>
                  <i className="bi bi-x"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div >
        <ConfigDeleteAll/>
      </div>
    </>
  );
};
