import axios, { AxiosRequestHeaders } from "axios";
import { snakeToCamel } from "./apiMapper";


export const adminService = {
  async getIsAdmin(authHeader: AxiosRequestHeaders): Promise<boolean> {
    const url = `/api/admin/is_admin`;
    const options = { headers: authHeader };
    const response = await axios.get(url, options);
    return snakeToCamel(response.data);
  }
}