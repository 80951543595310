import { useParams } from "react-router-dom";
import { Spinner } from "../../components/ui/Spinner";
import { useGetCourseQuery, useGetRecordingsBlacklistQuery, useGetRecordingsWhitelistQuery } from "../../hooks/courseHooks";
import { useGetIsTeacherOrAdminQuery } from "../../hooks/teacherHooks";
import { Recording } from "../../models/Recording";
import { BlacklistRecordingCard } from "./BlacklistRecordingCard";
import { RecordingCard } from "./RecordingCard";

export const CourseDetail = () => {
  const { courseId } = useParams();
  if (!courseId) throw "Invalid Course"

  const courseQuery = useGetCourseQuery(courseId);
  const course = courseQuery.data;

  const recordingWhitelistQuery = useGetRecordingsWhitelistQuery(courseId);
  const recordingBlacklistQuery = useGetRecordingsBlacklistQuery(courseId);
  const isTeacherOrAdminQuery = useGetIsTeacherOrAdminQuery();

  var recordings: Recording[][] = []
  if (recordingWhitelistQuery.data) {
    recordings = SortRecordingsByWeek(recordingWhitelistQuery.data).reverse()
  }

  if (courseQuery.isFetching) return <Spinner />
  if (recordingWhitelistQuery.isFetching) return (
    <div className="text-center">
      <h1>{course?.name}</h1>
      <h4 className="mt-3">Loading recordings...</h4>
      <Spinner />
    </div>
  )
  if (recordingWhitelistQuery.data?.length === 0 && recordingBlacklistQuery.data?.length === 0) return (
    <div className="text-center">
      <h1>{course?.name}</h1>
      <h4 className="mt-3">No Recordings Found</h4>
    </div>
  )
  return (
    <div className="container">
      <h1 className="text-center">{course?.name}</h1>
      <div>
        {recordings?.map((w) => (
          <div>
            <h4>{GetWeekRangeString(w)}</h4>
            <div className="row">
              {w.map((r) => (
                <RecordingCard
                  courseId={courseId}
                  recording={r}
                  canBlacklist={isTeacherOrAdminQuery.data}
                  key={r.ivcPath}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      {isTeacherOrAdminQuery.data === true ? (
        <>
          <h3 className="text-center border-bottom border-2 border-dark">Blacklisted:</h3>
          <div className="row">
            {recordingBlacklistQuery.data?.map((r) => (
              <BlacklistRecordingCard courseId={courseId} recording={r} key={r.ivcPath} />
            ))}
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  )
}

function SortRecordingsByWeek(recordings: Recording[]) {
  if (recordings.length === 0) return []

  const weeks: string[] = InitializeWeeks(recordings);

  const sortedRecordings: Recording[][] = []
  for (var week = 0; week < weeks.length; week++) {
    sortedRecordings[week] = []
  }
  for (var i = 0; i < recordings.length; i++) {
    const weekRange = GetWeekRangeString([recordings[i]])
    const weekIndex = weeks.findIndex(w => weekRange === w)
    if (weekIndex === -1) {
      sortedRecordings[weeks.length - 1].push(recordings[i])
    }
    else {
      sortedRecordings[weekIndex].push(recordings[i])
    }
  }
  return sortedRecordings
}

function InitializeWeeks(recordings: Recording[]) {
  const weeks: string[] = []
  for (var i = 0; i < recordings.length; i++) {
    const weekRange = GetWeekRangeString([recordings[i]]);
    if (weeks.length === 0)
      weeks.push(weekRange);
    else {
      const weekIndex = weeks.findIndex(w => weekRange === w);
      if (weekIndex === -1) {
        weeks.push(weekRange);
      }
    }
  }
  return weeks;
}

function GetWeekRange(recordings: Recording) {
  const firstDayMilli = new Date(recordings.sTime).valueOf()
  const dayOfWeek = new Date(recordings.sTime).getDay() - 1

  const startDate = new Date(firstDayMilli - (dayOfWeek * 86400000))
  const lastDate = new Date(startDate.valueOf() + (6 * 86400000))
  return { startDate, lastDate }
}

function GetWeekRangeString(recordings: Recording[]) {
  if (recordings.length === 0) return ""
  const dates = GetWeekRange(recordings[0])
  const dateString = (dates.startDate.getMonth() + 1) + "/" + dates.startDate.getDate() + "-" + (dates.lastDate.getMonth() + 1) + "/" + dates.lastDate.getDate()
  return dateString
}
