import { FC } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Course } from "../../models/Course";


export const CourseCard: FC<{
  course: Course;
}> = ({ course }) => {
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate({
      pathname: `/course/${course.id}`
    })
  }

  return (
    <div className="card-group col-lg-3 col-md-4 col-12 my-md-2" onClick={clickHandler}>
      <div className="card">
        <div className="card-header py-5 bg-primary"></div>
        <div className="card-body">
          <div className="row">
            <div className="col text-center">{course.name}</div>
          </div>
        </div>
      </div>
    </div>
  )
}