import { useState } from "react";
import { FilterTextRow } from "../../components/forms/FilterTextRow";
import { Spinner } from "../../components/ui/Spinner";
import { useGetEnrolledCoursesQuery } from "../../hooks/courseHooks";
import { CourseCard } from "./CourseCard";

export const Home = () => {
  const [filter, setFilter] = useState("");
  const coursesQuery = useGetEnrolledCoursesQuery();
  const filteredCourses = coursesQuery.data?.filter((c) =>
    c.name.toLowerCase().includes(filter.toLowerCase())
  );

  if (coursesQuery.isFetching) return <Spinner />;

  if (!coursesQuery.data || coursesQuery.data?.length === 0)
    return (
      <div className="container text-center">
        <h1>Courses</h1>
        <hr/>
        <h3>No Course Found</h3>
      </div>
    );

  return (
    <div className="container text-center">
      <div className="mx-5 px-lg-5">
        <h1>Courses</h1>
        <hr/>
        <FilterTextRow
          changeHandler={(e: any) => setFilter(e)}
          value={filter}
          labelCol={"col-lg-4 col-3"}
          inputCol={"col-lg-5 col-9"}
          placeholder={"Filter by course"}
        />
        <div className="row mt-2">
          {filteredCourses?.map((c) => (
            <CourseCard course={c} key={c.id} />
          ))}
        </div>
      </div>
    </div>
  );
};
