import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap";
import "./assets/custom.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { QueryClientProvider } from "react-query";
import { getQueryClient } from "./services/queryClient";
import React from "react";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

if (!process.env.REACT_APP_SSO_URL) throw Error("REACT_APP_SSO_URL not set");
if (!process.env.REACT_APP_BASE_URL) throw Error("REACT_APP_BASE_URL not set");
if (!process.env.REACT_APP_OIDC_CLIENT_ID)
  throw Error("REACT_APP_OIDC_CLIENT_ID not set");
const ssoUrl = process.env.REACT_APP_SSO_URL;
const baseUrl = process.env.REACT_APP_BASE_URL;
const clientId = process.env.REACT_APP_OIDC_CLIENT_ID;

const oidcConfig = {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: ssoUrl + "/realms/snowcollege/",
  client_id: clientId,
  redirect_uri: baseUrl + "login/landing",
  silent_redirect_uri: window.location.origin + '/silentrenew',
  response_type: 'code',
  scope: "openid profile email",
  loadUserInfo: true,
  automaticSilentRenew: true
};
const queryClient = getQueryClient();

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
