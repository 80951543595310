import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { Admin } from "./pages/admin/Admin";
import { Configuration } from "./pages/config/Configuration";
import { CourseDetail } from "./pages/courseDetail/CourseDetail";
import { Home } from "./pages/home/Home";
import { RecordingView } from "./pages/recording/RecordingView";

const AppRoutes = () => {
  return (
    <>
      <NavBar />
      <Toaster />
      <br />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/config" element={<Configuration />} />
        <Route path="/course/:courseId" element={<CourseDetail />} />
        <Route path="/recording/:recordingId" element={<RecordingView/>} />
      </Routes>

    </>
  );
};
export default AppRoutes;
