import { useMutation, useQuery } from "react-query";
import { Recording } from "../models/Recording";
import { courseService } from "../services/courseService";
import { getQueryClient } from "../services/queryClient";
import { useAccessTokenHeader } from "./userHooks"

const coursesKey = "coursesKey"
const recordingKey = "recordingKey"
const blacklistRecordingKey = "blacklistRecordingKey"
const queryClient = getQueryClient();

export const useGetEnrolledCoursesQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery([coursesKey], async () => {
    return courseService.getStudentCourses(authHeader);
  })
}

export const useGetCourseQuery = (courseId: string) => {
  const authHeader = useAccessTokenHeader();
  return useQuery([courseId], async () => {
    return courseService.getCourse(courseId, authHeader)
  })
}

export const useGetRecordingsWhitelistQuery = (courseId: string) => {
  const authHeader = useAccessTokenHeader();
  return useQuery([recordingKey, courseId], async () => {
    return courseService.getWhitelistedRecordings(courseId, authHeader)
  })
}

export const useGetRecordingsBlacklistQuery = (courseId: string) => {
  const authHeader = useAccessTokenHeader();
  return useQuery([blacklistRecordingKey, courseId], async () => {
    return courseService.getBlacklistedRecordings(courseId, authHeader)
  })
}

export const useWhitelistRecordingMutation = (courseId: string) => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async (recordingIvcPath: string) => await courseService.whitelistRecording(recordingIvcPath, authHeader),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([blacklistRecordingKey, courseId])
        queryClient.invalidateQueries([recordingKey, courseId])
      }
    }
  )
};

export const useBlacklistRecordingMutation = (courseId: string) => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async (recordingIvcPath: string) => await courseService.blacklistRecording(recordingIvcPath, authHeader),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([blacklistRecordingKey, courseId])
        queryClient.invalidateQueries([recordingKey, courseId])
      }
    }
  )
};
