import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useWhitelistTeacherMutation } from "../../hooks/adminHooks"
import { Person } from "../../models/Person"

export const BlacklistedTeacherSection: FC<{
  teacher: Person
}> = ({ teacher }) => {
  const navigate = useNavigate();
  const whitelistTeacherMutation = useWhitelistTeacherMutation();

  const clickHandler = (courseId: string) => {
    navigate({
      pathname: `/course/${courseId}`
    })
  }

  const whitelistHandler = () => {
    whitelistTeacherMutation.mutate(teacher.id)
  }

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-auto">
          <del><h2>{teacher.name}</h2></del>
        </div>
        <div className="col-1" onClick={whitelistHandler}>
          <i className="bi bi-person-plus h2"></i>
        </div>
      </div>
      {teacher.courses?.map((course) => (
        <div className="row border-bottom" key={course.id}>
          <div className="col my-auto">
            <a onClick={() => clickHandler(course.id)} className="fs-5"><del>{course.name}</del></a>
          </div>
        </div>
      )
      )}
    </div>
  )
}