import { useMutation, useQuery } from "react-query";
import { adminService } from "../services/adminService";
import { courseService } from "../services/courseService";
import { getQueryClient } from "../services/queryClient";
import { teacherService } from "../services/teacherService";
import { useAccessTokenHeader } from "./userHooks";

const teacherKey = "teacherKey"
const adminKey = "adminKey"
const blacklistTeacherKey = "blacklistTeacherKey"
const queryClient = getQueryClient();

export const useGetIsAdminQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery([adminKey], async () => {
    return adminService.getIsAdmin(authHeader);
  })
}

export const useGetWhitelistedTeachersQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery([teacherKey], async () => {
    return teacherService.getWhitelistedTeachers(authHeader);
  });
};

export const useGetBlacklistedTeachersQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery([blacklistTeacherKey], async () => {
    return teacherService.getBlacklistedTeachers(authHeader);
  });
}

export const useBlacklistTeacherMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async (teacherId: string) => await teacherService.blacklistTeacher(teacherId, authHeader),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([teacherKey])
        queryClient.invalidateQueries([blacklistTeacherKey])
      }
    }
  )
}

export const useWhitelistTeacherMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async (teacherId: string) => await teacherService.whitelistTeacher(teacherId, authHeader),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([teacherKey])
        queryClient.invalidateQueries([blacklistTeacherKey])
      }
    }
  )
}

export const useBlacklistCourseMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async (course_id: string) => await courseService.blacklistCourse(course_id, authHeader),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([teacherKey])
        queryClient.invalidateQueries([blacklistTeacherKey])
      }
    }
  )
};

export const useWhitelistCourseMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async (course_id: string) => await courseService.whitelistCourse(course_id, authHeader),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([teacherKey])
        queryClient.invalidateQueries([blacklistTeacherKey])
      }
    }
  )
};
