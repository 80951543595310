import { useParams } from "react-router-dom";

export const RecordingView = () => {
    const { recordingId } = useParams();
    if (!recordingId) throw "Invalid Recording"
    return (
        <div className="row">
            <div className="col"></div>
            <div className="col-9">
                <video
                    preload="none"
                    controls={true}
                    poster=""
                    width={"100%"}
                    autoPlay={true}
                >
                    <source src={`http://ivc.snow.edu/s/${recordingId}/download`} type="video/mp4" />
                </video>
            </div>
            <div className="col"></div>
        </div>
    )
}