import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useBlacklistCourseMutation, useBlacklistTeacherMutation, useWhitelistCourseMutation } from "../../hooks/adminHooks"
import { Person } from "../../models/Person"

export const TeacherSection: FC<{
  teacher: Person
}> = ({ teacher }) => {
  const navigate = useNavigate();
  const blacklistMutation = useBlacklistCourseMutation();
  const whitelistMutation = useWhitelistCourseMutation();
  const blacklistTeacherMutation = useBlacklistTeacherMutation();

  const clickHandler = (courseId:string) => {
    navigate({
      pathname: `/course/${courseId}`
    })
  }

  const blacklistCourseHandler = (courseId: string) => {
    blacklistMutation.mutate(courseId);
  }
  const whitelistCourseHandler = (courseId: string) => {
    whitelistMutation.mutate(courseId);
  }
  const blacklistTeacherHandler = () => {
    blacklistTeacherMutation.mutate(teacher.id)
  }

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-auto">
          <h2>{teacher.name}</h2>
        </div>
        <div className="col-1" onClick={blacklistTeacherHandler}>
          <i className="bi bi-person-dash-fill h2"></i>
        </div>
      </div>
      {teacher.courses?.map((course) => (
        <div key={course.id} className="row border-bottom">
          {course.isBlacklisted ? (
            <>
              <div className="col my-auto">
                <a onClick={() => clickHandler(course.id)} className="fs-5"><del>{course.name}</del></a>
              </div>
              <div className="col-1 text-end">
                <button className="btn btn-success py-0 px-2 my-1" onClick={() => whitelistCourseHandler(course.id)}>
                  <i className="bi bi-plus-square-fill"></i>
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="col my-auto">
                <a onClick={() => clickHandler(course.id)} className="fs-5">{course.name}</a>
              </div>
              <div className="col-1 text-end">
                <button className="btn btn-dark py-0 px-2 my-1" onClick={() => blacklistCourseHandler(course.id)}>
                  <i className="bi bi-x-square"></i>
                </button>
              </div>
            </>
          )}
        </div>
      )
      )}
    </div>
  )
  }